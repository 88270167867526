/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'services/global';
import { countdownToActualDraft, draftTimer } from 'services/timers';

const audio = new Audio('https://amplify-amplifyb685f7e5308d4-staging-232751-deployment.s3.us-east-2.amazonaws.com/chime.mp3');
const warning_audio = new Audio('https://amplify-amplifyb685f7e5308d4-staging-232751-deployment.s3.us-east-2.amazonaws.com/warning.mp3');

export function DraftHeader({ socket, username }) {
    //how much time left to draft
    const [draftTimerNewTime, setDraftTimerNewTime] = useState(null);
    const [draftTimerRemain, setDraftTimerRemain] = useState('');
    const [currentPick, setCurrentPick] = useState(null);
    const [currentDrafter, setCurrentDrafter] = useState('');
    const [onlineUsers, setOnlineUsers] = useState('');
    const [draftDate, setDraftDate] = useState('empty');

    //useEffect for the countdown to the actual draft, then stops once it's reached draft time!
    useEffect(() => {
        const countdownService = countdownToActualDraft();
        const subscription = countdownService.subscribe((draftDate) => {
            setDraftDate(draftDate);
        });

        if (draftDate === "00:30" || draftDate === "00:10" || draftDate === "00:09" || draftDate === "00:08" ||
            draftDate === "00:07" || draftDate === "00:06" || draftDate === "00:05" || draftDate === "00:04" ||
            draftDate === "00:03" || draftDate === "00:02" || draftDate === "00:01" || draftDate === "00:00") {
            warning_audio.play();
        }

        //when it's draft time, set out a socket to get the current pick and drafter, and get the new draft timer time
        //so that the clock can automatically start for the 1st pick
        if (draftDate === 'now' && isEmpty(draftTimerNewTime)) {
            socket.emit("getCurrentPickAndDrafter");
            socket.emit("getDraftTimerNewTime");
        }
        return () => {
            subscription.unsubscribe();
        };

    }, [draftDate]);

    useEffect(() => {
        if (currentDrafter !== username) {
            return;
        }
        //If there's 30 seconds left, play the really annoying warning noise to indicate times running out
        //If it's the last 10 seconds, play the really annoying warning noise to hurry the fuck up and pick
        if (draftTimerRemain === "0:30" || draftTimerRemain === "0:10" || draftTimerRemain === "0:09" || draftTimerRemain === "0:08" ||
            draftTimerRemain === "0:07" || draftTimerRemain === "0:06" || draftTimerRemain === "0:05" || draftTimerRemain === "0:04" ||
            draftTimerRemain === "0:03" || draftTimerRemain === "0:02" || draftTimerRemain === "0:01" || draftTimerRemain === "0:00") {
            warning_audio.play();
        }
    }, [draftTimerRemain, currentDrafter]);

    //auto select useEffect
    useEffect(() => {
        if ((draftDate !== 'now' && draftDate !== 'empty' && !isEmpty(draftDate)) || (isEmpty(onlineUsers))) {
            return;
        }

        const draftTimerService = draftTimer(draftTimerNewTime, currentDrafter, onlineUsers);

        const subscription = draftTimerService.subscribe((draftTimerRemain) => {
            setDraftTimerRemain(draftTimerRemain);
        });
        
        return () => {
            subscription.unsubscribe();
        };
    }, [draftTimerNewTime, onlineUsers]);

    //only run this once, so have empty callback array
    useEffect(() => {
        socket.on("setOnlineUsers", data => {
            setOnlineUsers(data);
        });
    }, []);

    useEffect(() => {
        socket.on("setCurrentPickAndDrafter", (currentPick, currentDrafter) => {
            setCurrentDrafter(currentDrafter);
            setCurrentPick(currentPick);
            socket.emit("getCurrDrafterLastSeen", currentDrafter);
        });
        socket.on("updateDraftTimer", (draftTimerNewTime) => {
            setDraftTimerNewTime(draftTimerNewTime);
        });
    }, []);

    useEffect(() => {
        if ((isEmpty(draftDate) || draftDate === 'now') && username === currentDrafter) {
            document.title = 'YOUR PICK!!!';
            audio.play();
        }
    }, [draftDate, currentPick]);
    
    //don't include currentPick in the array below since setCurrentPick is called before setCurrentDrafter...
    //therefore, it plays the sound since username === currentDrafter still
    //UPDATED OCT 2024: currentPick needs to be in that array, or else the tab wont update if people had BTB picks.
    //I have seen no issue testing in Oct 2024 
    useEffect(() => {
        if ((draftDate === 'empty' || !isEmpty(draftDate)) && draftDate !== 'now' && draftDate !== 'completed') {
            let word = draftDate;

            if (draftDate === 'empty') {
                word = 'Loading...';
            }
            document.title = (word + " until the draft!");
        }
        else if(draftDate === 'completed') {
            document.title = "Draft Complete!";
        }
        else {
            document.title = "PICK #" + currentPick + ": " + currentDrafter.toUpperCase();
        }
    }, [draftDate, currentPick]);

    return (
        <>
            {(isEmpty(draftDate) || draftDate === 'empty') &&
                <h2 className="fixed-top tac draftH2">
                    Loading...
                </h2>
            }
            {(!isEmpty(draftDate) && draftDate !== 'now' && draftDate !== 'empty' && draftDate !== 'completed') &&
                <h2 className="fixed-top tac draftH2">
                    {draftDate} until the draft!
                </h2>
            }
            {(!isEmpty(draftDate) && draftDate === 'completed') &&
                <h2 className="fixed-top tac draftH2">
                    Draft Complete! Thanks to everyone for participating.
                </h2>
            }
            {/* For testing purposes, remove draftDate === 'now' from this if to test the drafting process */}
            {((isEmpty(draftDate) || draftDate === 'now') && currentPick !== null && currentDrafter !== '' && draftTimerRemain === 'expired') &&
                <h2 className="fixed-top tac draftH2">
                    CURRENT PICK: #{currentPick} - {currentDrafter} : 0:00
                </h2>
            }
            {((isEmpty(draftDate) || draftDate === 'now') && currentPick !== null && currentDrafter !== '' && draftTimerRemain !== 'expired') &&
                <h2 className="fixed-top tac draftH2">
                    CURRENT PICK: #{currentPick} - {currentDrafter} {(isEmpty(draftTimerRemain) || draftTimerRemain === 'autodraft') ? ''
                        : ": " + draftTimerRemain}
                </h2>
            }
            <div className="row">
                <div className="col-12">
                    <label className="inline-block"><b>Users online:</b></label>
                    <p className="inline-block">&nbsp; {onlineUsers}</p>
                </div>
            </div>
        </>
    )
}

export default DraftHeader;